<template>
    <div>
        <v-btn :loading="loading.url" small class="ml-2 lighten-2" v-if="updateMode && !listMode" @click.stop="printContract()">
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.pdf') }}</span>
        </v-btn>
        <v-list-item class="font-sm" @click.stop="printContract()" v-if="updateMode && listMode">
            <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.url"/>
            <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
            <span>{{ $t('message.contract') }}</span>
        </v-list-item>
    </div>
</template>

<script>

import {mapFields} from "vuex-map-fields";
import {api} from "Api";
import PDFMerger from "pdf-merger-js";

export default {
    name: "PrintContractButton",
    props: ['contractId','updateMode','listMode','salesList','contractItem','mergeableDocs'],
    data() {
        return {
            loading: {
                url: false
            }
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        hasMergeableDocs() {
            if(Array.isArray(this.mergeableDocs) && this.mergeableDocs.length > 0){
                return true
            }
            return false
        }
    },
    methods: {
        getPrintPdfUrl() {
            return new Promise((resolve, reject) => {
                api
                    .get("/print-url/contract/" + this.contractId)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        printContract() {
            let salesTypeId = null
            if(this.salesList){
                salesTypeId = this.contractItem.Sale.salestype_id
            } else {
                salesTypeId = this.contractItem.Contract.salestype_id
            }
            if([1,5].includes(salesTypeId)) {
                this.loading.url = true;
                this.getPrintPdfUrl()
                    .then(response => {
                        if (response.data.status == 'success') {
                            if (this.hasMergeableDocs) {
                                const mainPdf = response.data.url
                                const mergePdfs = async () => {
                                    const merger = new PDFMerger();
                                    const items = [mainPdf, ...this.mergeableDocs]
                                    for (const file of items) {
                                        await merger.add(file)
                                    }
                                    const mergedPdf = await merger.saveAsBuffer()
                                    return mergedPdf
                                }
                                mergePdfs()
                                    .then((pdfBuffer) => {
                                        const url = window.URL.createObjectURL(new Blob([pdfBuffer], {type: 'application/pdf'}));
                                        const link = document.createElement('a');
                                        let thisDocument = 'contract'
                                        if(this.salesList){
                                            thisDocument = encodeURIComponent([this.contractItem.Sale.Contract.title, this.contractItem.Sale.Contract.revision_no, this.contractItem.Sale.Contract.partial_no].filter(Boolean).join('-'))
                                        } else {
                                            thisDocument = encodeURIComponent([this.contractItem.Contract.title, this.contractItem.Contract.revision_no, this.contractItem.Contract.partial_no].filter(Boolean).join('-'))
                                        }
                                        link.href = url;
                                        link.target = '_blank'
                                        link.setAttribute('download', thisDocument + '.pdf');
                                        link.click()
                                        link.remove();
                                        window.URL.revokeObjectURL(url);
                                        this.loading.url = false
                                    })
                                    .catch(() => {
                                        this.loading.url = false
                                        this.$toast.error(this.$t('message.errors.pdfError'), {
                                            timeout: 1000,
                                            x: 'center',
                                            y: 'top'
                                        })
                                    })
                            } else {
                                let tab = window.open(response.data.url, '_blank');
                                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                                this.openedTabs.push(tab.name)
                                this.loading.url = false
                            }
                        } else {
                            this.$toast.error(this.$t('message.errors.pdfError'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                })
                            this.loading.url = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                        this.loading.url = false
                    })
            } else if(salesTypeId == 2) {
                if(this.contractItem.Contract.version != null){
                    if (this.contractItem.Contract.SignedOrder.location != null) {
                        let tab = window.open(this.contractItem.Contract.SignedOrder.location, "_blank")
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        if(this.contractItem.Contract.TmsSignedOrder.location != null){
                            let tab = window.open(this.contractItem.Contract.TmsSignedOrder.location,"_blank")
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        } else {
                            this.$toast.error(this.$t('message.signedContractNotAvailable'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                        }
                    }
                } else {
                    if(this.contractItem.Contract.TmsSignedOrder.location != null){
                        let tab = window.open(this.contractItem.Contract.TmsSignedOrder.location,"_blank")
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        this.$toast.error(this.$t('message.signedContractNotAvailable'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        })
                    }
                }
            } else {
                return false
            }
        }
    }
}
</script>

<style scoped>

</style>